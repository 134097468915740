/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BadgeProgressBarDto } from '../models/badge-progress-bar-dto';
import { createFeed } from '../fn/feed-controller/create-feed';
import { CreateFeed$Params } from '../fn/feed-controller/create-feed';
import { deleteFeed } from '../fn/feed-controller/delete-feed';
import { DeleteFeed$Params } from '../fn/feed-controller/delete-feed';
import { FeedDto } from '../models/feed-dto';
import { getAllFeeds } from '../fn/feed-controller/get-all-feeds';
import { GetAllFeeds$Params } from '../fn/feed-controller/get-all-feeds';
import { getBadgeProgressBar } from '../fn/feed-controller/get-badge-progress-bar';
import { GetBadgeProgressBar$Params } from '../fn/feed-controller/get-badge-progress-bar';

@Injectable({ providedIn: 'root' })
export class FeedControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllFeeds()` */
  static readonly GetAllFeedsPath = '/feeds/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFeeds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFeeds$Response(params?: GetAllFeeds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FeedDto>>> {
    return getAllFeeds(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllFeeds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFeeds(params?: GetAllFeeds$Params, context?: HttpContext): Observable<Array<FeedDto>> {
    return this.getAllFeeds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FeedDto>>): Array<FeedDto> => r.body)
    );
  }

  /** Path part for operation `createFeed()` */
  static readonly CreateFeedPath = '/feeds/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFeed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFeed$Response(params: CreateFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFeed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFeed(params: CreateFeed$Params, context?: HttpContext): Observable<string> {
    return this.createFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getBadgeProgressBar()` */
  static readonly GetBadgeProgressBarPath = '/feeds/badgeProgressBar';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBadgeProgressBar()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBadgeProgressBar$Response(params?: GetBadgeProgressBar$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BadgeProgressBarDto>>> {
    return getBadgeProgressBar(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBadgeProgressBar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBadgeProgressBar(params?: GetBadgeProgressBar$Params, context?: HttpContext): Observable<Array<BadgeProgressBarDto>> {
    return this.getBadgeProgressBar$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BadgeProgressBarDto>>): Array<BadgeProgressBarDto> => r.body)
    );
  }

  /** Path part for operation `deleteFeed()` */
  static readonly DeleteFeedPath = '/feeds/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFeed()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFeed$Response(params: DeleteFeed$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return deleteFeed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFeed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFeed(params: DeleteFeed$Params, context?: HttpContext): Observable<string> {
    return this.deleteFeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createFormTemplate } from '../fn/form-template-controller/create-form-template';
import { CreateFormTemplate$Params } from '../fn/form-template-controller/create-form-template';
import { deleteFormTemplateById } from '../fn/form-template-controller/delete-form-template-by-id';
import { DeleteFormTemplateById$Params } from '../fn/form-template-controller/delete-form-template-by-id';
import { FormTemplateCardDto } from '../models/form-template-card-dto';
import { FormTemplateOutputDto } from '../models/form-template-output-dto';
import { getFormsFromTemplateToExcel } from '../fn/form-template-controller/get-forms-from-template-to-excel';
import { GetFormsFromTemplateToExcel$Params } from '../fn/form-template-controller/get-forms-from-template-to-excel';
import { getFormsToExcelsInZip } from '../fn/form-template-controller/get-forms-to-excels-in-zip';
import { GetFormsToExcelsInZip$Params } from '../fn/form-template-controller/get-forms-to-excels-in-zip';
import { getFormTemplateById } from '../fn/form-template-controller/get-form-template-by-id';
import { GetFormTemplateById$Params } from '../fn/form-template-controller/get-form-template-by-id';
import { getFormTemplateCards } from '../fn/form-template-controller/get-form-template-cards';
import { GetFormTemplateCards$Params } from '../fn/form-template-controller/get-form-template-cards';
import { updateFormTemplate } from '../fn/form-template-controller/update-form-template';
import { UpdateFormTemplate$Params } from '../fn/form-template-controller/update-form-template';

@Injectable({ providedIn: 'root' })
export class FormTemplateControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getFormTemplateCards()` */
  static readonly GetFormTemplateCardsPath = '/formTemplates/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFormTemplateCards()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormTemplateCards$Response(params?: GetFormTemplateCards$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FormTemplateCardDto>>> {
    return getFormTemplateCards(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFormTemplateCards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormTemplateCards(params?: GetFormTemplateCards$Params, context?: HttpContext): Observable<Array<FormTemplateCardDto>> {
    return this.getFormTemplateCards$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FormTemplateCardDto>>): Array<FormTemplateCardDto> => r.body)
    );
  }

  /** Path part for operation `updateFormTemplate()` */
  static readonly UpdateFormTemplatePath = '/formTemplates/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFormTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormTemplate$Response(params: UpdateFormTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<FormTemplateOutputDto>> {
    return updateFormTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFormTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateFormTemplate(params: UpdateFormTemplate$Params, context?: HttpContext): Observable<FormTemplateOutputDto> {
    return this.updateFormTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormTemplateOutputDto>): FormTemplateOutputDto => r.body)
    );
  }

  /** Path part for operation `createFormTemplate()` */
  static readonly CreateFormTemplatePath = '/formTemplates/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createFormTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormTemplate$Response(params: CreateFormTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createFormTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createFormTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createFormTemplate(params: CreateFormTemplate$Params, context?: HttpContext): Observable<string> {
    return this.createFormTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getFormTemplateById()` */
  static readonly GetFormTemplateByIdPath = '/formTemplates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFormTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormTemplateById$Response(params: GetFormTemplateById$Params, context?: HttpContext): Observable<StrictHttpResponse<FormTemplateOutputDto>> {
    return getFormTemplateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFormTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormTemplateById(params: GetFormTemplateById$Params, context?: HttpContext): Observable<FormTemplateOutputDto> {
    return this.getFormTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FormTemplateOutputDto>): FormTemplateOutputDto => r.body)
    );
  }

  /** Path part for operation `deleteFormTemplateById()` */
  static readonly DeleteFormTemplateByIdPath = '/formTemplates/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFormTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFormTemplateById$Response(params: DeleteFormTemplateById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteFormTemplateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFormTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFormTemplateById(params: DeleteFormTemplateById$Params, context?: HttpContext): Observable<void> {
    return this.deleteFormTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `getFormsFromTemplateToExcel()` */
  static readonly GetFormsFromTemplateToExcelPath = '/formTemplates/excel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFormsFromTemplateToExcel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormsFromTemplateToExcel$Response(params: GetFormsFromTemplateToExcel$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getFormsFromTemplateToExcel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFormsFromTemplateToExcel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormsFromTemplateToExcel(params: GetFormsFromTemplateToExcel$Params, context?: HttpContext): Observable<Blob> {
    return this.getFormsFromTemplateToExcel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `getFormsToExcelsInZip()` */
  static readonly GetFormsToExcelsInZipPath = '/formTemplates/excel/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFormsToExcelsInZip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormsToExcelsInZip$Response(params?: GetFormsToExcelsInZip$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return getFormsToExcelsInZip(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFormsToExcelsInZip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFormsToExcelsInZip(params?: GetFormsToExcelsInZip$Params, context?: HttpContext): Observable<Blob> {
    return this.getFormsToExcelsInZip$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}

import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { AuthService } from '.';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.authService.user$.pipe(
      filter((x) => !!x),
      map((x) => !!x)
    );
  }

  canActivateChild(): Observable<boolean> | boolean {
    return this.canActivate();
  }
}

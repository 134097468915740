<div class="modal flex flex-col gap-2 min-h-fit">
  <h1 class="!font-bold text-3xl flex !px-5 !pt-4 !pb-2 !justify-center">
    {{ title }}
  </h1>
  <div
    mat-dialog-content
    class="!px-5 !py-1 !flex !justify-center !items-center"
  >
    <span>{{ content }}</span>
  </div>
  <div
    mat-dialog-actions
    class="flex gap-4 !justify-center !px-5 !pb-4 !pt-2"
  >
    <button
      mat-raised-button
      mat-dialog-close
      class="!rounded-3xl"
      (click)="close()"
      color="accent"
    >
      {{ 'common.modal.cancel' | translate }}
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      cdkFocusInitial
      class="!rounded-3xl"
      color="primary"
      [mat-dialog-close]="true"
    >
      {{ 'common.modal.confirm' | translate }}
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { AuthService } from '.';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardAnonymousService {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> | boolean {
    return this.authService.user$.pipe(map((x) => !x));
  }
  canActivateAdmin(): Observable<boolean> | boolean {
    return this.authService.user$.pipe(map((x) => !!x?.admin));
  }

}

import {Injectable} from '@angular/core';
import {IAppUser, Model} from '@base';
import {OAuthService} from 'angular-oauth2-oidc';
import {catchError, from, map, Observable, of, switchMap, take, tap} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AuthControllerService, GdsControllerService} from '@api/services';
import {UserDto} from '@api/models';
import {log} from "util";

@Injectable({providedIn: 'root'})
export class AuthService {
    private userModel = Model.create<IAppUser | null>(null);
    public user$ = this.userModel.data$;

    public get user() {
        return this.userModel.get();
    }

    constructor(
        private authControllerService: AuthControllerService,
        private gdsService: GdsControllerService,
        private oauthService: OAuthService
    ) {
        this.oauthService.configure(environment.authConfig);

        this.oauthService.setStorage(localStorage);
        from(this.oauthService.loadDiscoveryDocument()).pipe(take(1)).subscribe();
    }

    public login(email: string): Observable<boolean> {
        if (!this.isValidToken()) {
            this.oauthService.initLoginFlow();
            return of(false);
        }

        console.log(`Logged in: ${email}`);
        return of(true);
    }

    public getGdsServer(): Observable<String> {
        return this.gdsService.init({});


    }

    public getCurrentUser(): Observable<IAppUser | undefined> {
        if (!this.isValidToken()) {
            return from(this.oauthService.tryLogin()).pipe(
                tap((isLoggedIn) => {
                    if (!!isLoggedIn) {
                        const access_token = this.oauthService.getAccessToken();
                        if (access_token !== null) {
                            localStorage.setItem('access_token ', access_token);
                        }
                    }
                }),
                switchMap(() => from(this.oauthService.loadUserProfile())),
                map((userAd: any) =>  userAd.info.email),
                switchMap((email: string) => {
                    return this.authControllerService.login({email: email});
                }),
                map((user) => this.populateData(user)),
                tap((user) => this.userModel.set(user ?? null))
            );
        }

        return from(this.oauthService.loadUserProfile())
            .pipe(
                map((userAd: any) => userAd.info.email),
                catchError(() => {
                    this.oauthService.logOut();
                    localStorage.setItem('access_token ', '');
                    return of();
                })
            )
            .pipe(
                switchMap((email: string) => {
                    return this.authControllerService.login({email: email});
                }),
                map((user) => this.populateData(user)),
                tap((user) => this.userModel.set(user ?? null))
            );
    }

    private isValidToken() {
        return this.oauthService.hasValidAccessToken() && localStorage.getItem('access_token ') !== 'null';
    }

    private populateData(user: UserDto): IAppUser | undefined {
        return {
            ...user,
            image: './assetFs/default_avatar.png'
        };
    }
}

const locationOrigin = typeof window !== 'undefined' ? window.location.origin : '';

export const environment = {
  production: true,
  apiUrl : 'https://gds-backend-springapps-gds-backend-dev.azuremicroservices.io',

  authConfig: {
    issuer: 'https://idp-int.trustid.ch/auth/realms/gds',

    redirectUri: locationOrigin + '/#/feed',

    postLogoutRedirectUri: locationOrigin + '/#/login',

    clientId: 'gds-portal',

    responseType: 'code',

    scope: 'profile',

    silentRefreshRedirectUri: `${locationOrigin}/silent-refresh.html`,

    useSilentRefresh: false,

    showDebugInformation: true,

    sessionChecksEnabled: false,

    timeoutFactor: 0.01,
    oidc: true,

    requestAccessToken: false,
    dummyClientSecret: 'BDV82QioW0WcgzjqOqZwJn4EBgvN3ciT',

    tokenEndpoint: 'https://idp-int.trustid.ch/auth/realms/gds/protocol/openid-connect/token',
    revocationEndpoint: 'https://idp-int.trustid.ch/auth/realms/gds/protocol/openid-connect/revoke',
    userinfoEndpoint: 'https://idp-int.trustid.ch/auth/realms/gds/protocol/openid-connect/userinfo'
  }
};

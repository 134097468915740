/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AnnouncementDto } from '../models/announcement-dto';
import { createAnnouncement } from '../fn/announcement-controller/create-announcement';
import { CreateAnnouncement$Params } from '../fn/announcement-controller/create-announcement';
import { deleteAnnouncement } from '../fn/announcement-controller/delete-announcement';
import { DeleteAnnouncement$Params } from '../fn/announcement-controller/delete-announcement';
import { getAnnouncementById } from '../fn/announcement-controller/get-announcement-by-id';
import { GetAnnouncementById$Params } from '../fn/announcement-controller/get-announcement-by-id';
import { getAnnouncements } from '../fn/announcement-controller/get-announcements';
import { GetAnnouncements$Params } from '../fn/announcement-controller/get-announcements';
import { updateAnnouncement } from '../fn/announcement-controller/update-announcement';
import { UpdateAnnouncement$Params } from '../fn/announcement-controller/update-announcement';

@Injectable({ providedIn: 'root' })
export class AnnouncementControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAnnouncement()` */
  static readonly UpdateAnnouncementPath = '/announcements/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAnnouncement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnouncement$Response(params: UpdateAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<AnnouncementDto>> {
    return updateAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAnnouncement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAnnouncement(params: UpdateAnnouncement$Params, context?: HttpContext): Observable<AnnouncementDto> {
    return this.updateAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnouncementDto>): AnnouncementDto => r.body)
    );
  }

  /** Path part for operation `getAnnouncements()` */
  static readonly GetAnnouncementsPath = '/announcements/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnnouncements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnouncements$Response(params?: GetAnnouncements$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AnnouncementDto>>> {
    return getAnnouncements(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAnnouncements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnouncements(params?: GetAnnouncements$Params, context?: HttpContext): Observable<Array<AnnouncementDto>> {
    return this.getAnnouncements$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AnnouncementDto>>): Array<AnnouncementDto> => r.body)
    );
  }

  /** Path part for operation `createAnnouncement()` */
  static readonly CreateAnnouncementPath = '/announcements/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAnnouncement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAnnouncement$Response(params: CreateAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createAnnouncement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAnnouncement(params: CreateAnnouncement$Params, context?: HttpContext): Observable<string> {
    return this.createAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getAnnouncementById()` */
  static readonly GetAnnouncementByIdPath = '/announcements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAnnouncementById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnouncementById$Response(params: GetAnnouncementById$Params, context?: HttpContext): Observable<StrictHttpResponse<AnnouncementDto>> {
    return getAnnouncementById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAnnouncementById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAnnouncementById(params: GetAnnouncementById$Params, context?: HttpContext): Observable<AnnouncementDto> {
    return this.getAnnouncementById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnnouncementDto>): AnnouncementDto => r.body)
    );
  }

  /** Path part for operation `deleteAnnouncement()` */
  static readonly DeleteAnnouncementPath = '/announcements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAnnouncement()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAnnouncement$Response(params: DeleteAnnouncement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteAnnouncement(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAnnouncement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAnnouncement(params: DeleteAnnouncement$Params, context?: HttpContext): Observable<void> {
    return this.deleteAnnouncement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

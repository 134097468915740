import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ToastService {
  constructor(
    private snackbar: MatSnackBar,
    private translateService: TranslateService,
    private zone: NgZone
  ) {}

  public showSuccessToast(message: string, cancelMessage?: string) {
    return this.openToast(message, cancelMessage ?? this.translateService.instant('toast.ok'), 'primary');
  }

  public showWarningToast(message: string, cancelMessage?: string) {
    return this.openToast(message, cancelMessage ?? this.translateService.instant('toast.ok'), 'warn');
  }

  public showErrorToast(message: string, cancelMessage?: string) {
    return this.openToast(message, cancelMessage ?? this.translateService.instant('toast.ok'), 'error');
  }

  public openToast(message: string, cancelMessage?: string, state: 'primary' | 'warn' | 'error' = 'primary') {
    return this.zone.run(() => {
      const ref = this.snackbar.open(message, cancelMessage, {
        duration: 5000,
        panelClass: state
      });
      return ref;
    });
  }
}

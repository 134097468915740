import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { ConfirmDialogComponent, LogoComponent } from './components';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastService } from './services';

const DECLARED_EXPORTS = [LogoComponent, ConfirmDialogComponent];
const RELAYED_EXPORTS = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  MatButtonModule,
  MatTooltipModule,
  MatIconModule,
  MatCardModule,
  MatDialogModule,
  MatSnackBarModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatProgressSpinnerModule
];

@NgModule({
  declarations: [...DECLARED_EXPORTS],
  providers: [ToastService],
  imports: [RouterModule, ...RELAYED_EXPORTS],
  exports: [...RELAYED_EXPORTS, ...DECLARED_EXPORTS]
})
export class BaseModule {}

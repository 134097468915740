/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BadgeDto } from '../models/badge-dto';
import { createBadge } from '../fn/badge-controller/create-badge';
import { CreateBadge$Params } from '../fn/badge-controller/create-badge';
import { deleteBadge } from '../fn/badge-controller/delete-badge';
import { DeleteBadge$Params } from '../fn/badge-controller/delete-badge';
import { getAllBadges } from '../fn/badge-controller/get-all-badges';
import { GetAllBadges$Params } from '../fn/badge-controller/get-all-badges';
import { getBadgeById } from '../fn/badge-controller/get-badge-by-id';
import { GetBadgeById$Params } from '../fn/badge-controller/get-badge-by-id';
import { updateBadge } from '../fn/badge-controller/update-badge';
import { UpdateBadge$Params } from '../fn/badge-controller/update-badge';

@Injectable({ providedIn: 'root' })
export class BadgeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateBadge()` */
  static readonly UpdateBadgePath = '/badges/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBadge()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBadge$Response(params: UpdateBadge$Params, context?: HttpContext): Observable<StrictHttpResponse<BadgeDto>> {
    return updateBadge(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBadge$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateBadge(params: UpdateBadge$Params, context?: HttpContext): Observable<BadgeDto> {
    return this.updateBadge$Response(params, context).pipe(
      map((r: StrictHttpResponse<BadgeDto>): BadgeDto => r.body)
    );
  }

  /** Path part for operation `getAllBadges()` */
  static readonly GetAllBadgesPath = '/badges/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBadges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBadges$Response(params?: GetAllBadges$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BadgeDto>>> {
    return getAllBadges(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllBadges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBadges(params?: GetAllBadges$Params, context?: HttpContext): Observable<Array<BadgeDto>> {
    return this.getAllBadges$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BadgeDto>>): Array<BadgeDto> => r.body)
    );
  }

  /** Path part for operation `createBadge()` */
  static readonly CreateBadgePath = '/badges/';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createBadge()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBadge$Response(params: CreateBadge$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return createBadge(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createBadge$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createBadge(params: CreateBadge$Params, context?: HttpContext): Observable<string> {
    return this.createBadge$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `getBadgeById()` */
  static readonly GetBadgeByIdPath = '/badges/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBadgeById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBadgeById$Response(params: GetBadgeById$Params, context?: HttpContext): Observable<StrictHttpResponse<BadgeDto>> {
    return getBadgeById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBadgeById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBadgeById(params: GetBadgeById$Params, context?: HttpContext): Observable<BadgeDto> {
    return this.getBadgeById$Response(params, context).pipe(
      map((r: StrictHttpResponse<BadgeDto>): BadgeDto => r.body)
    );
  }

  /** Path part for operation `deleteBadge()` */
  static readonly DeleteBadgePath = '/badges/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBadge()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBadge$Response(params: DeleteBadge$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteBadge(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBadge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBadge(params: DeleteBadge$Params, context?: HttpContext): Observable<void> {
    return this.deleteBadge$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}

import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

export class GdsValidator {
    public static linkedinLink(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        if (/^https:\/\/linkedin.com\/.*/.test(value) || (/^https:\/\/www.linkedin.com\/.*/.test(value))) {
            return null;
        }

        return {linkedinLink: true, requiredValue: 'https://linkedin.com/'};
    }

    public static matchFormatValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value;
        return !value ? {dateFormat: true} : null;
    }
}
